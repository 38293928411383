import styles from './navbar.module.scss';
import { useEffect, useState } from 'react';
import { Link, useLocation, useShared } from '@interactivevision/visitor-react';
import { route } from '@interactivevision/routing';
import { ReactComponent as Logo } from '@assets/icons/company-logo_dark.svg';
import classNames from 'classnames';
import Burger from './burger';
import { SectionWrapper } from './wrappers';

export default function Navbar() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [scrolled, setScrolled] = useState<boolean>(false);
  const location = useLocation();
  const shared = useShared();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 20) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    });
  }, []);

  return (
    <nav className={classNames(styles.navbar, { [styles.scrolled]: scrolled })} id="navbar">
      <SectionWrapper as="div" className={styles.content}>
        <Link href={route('homepage_module')} className={styles.logo} title={import.meta.env.VITE_APP_NAME}>
          <Logo/>
        </Link>

        <ul className={classNames(styles.menu, { [styles.open]: isOpen })}>
          {shared?.menu.menu_top && shared?.menu.menu_top.map((element, index) => (
            <li className={styles.link} key={index}>
              <Link href={element.url}>{element.name}</Link>
            </li>
          ))}
        </ul>

        <Burger toggle={toggleMenu} active={isOpen}/>
      </SectionWrapper>
    </nav>
  );
};

import { createElement, ComponentType } from 'react';
import { RouterProps } from '@interactivevision/visitor-react';

type SetupOptions = {
  router: ComponentType<RouterProps>;
  props: RouterProps;
}

export default function app({ router, props }: SetupOptions) {
  return createElement(router, props);
};

import styles from './base.module.scss';
import Navbar from '../layout/navbar';
import Footer from './footer';

export const Base = ({ children }) => {
  return (
    <>
      <Navbar/>
      <main className={styles.base}>
        {children}
      </main>
      <Footer/>
    </>
  );
};

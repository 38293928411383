import styles from './footer.module.scss';
import { ReactComponent as CompanyLogo } from '@assets/icons/company-logo_footer.svg';
import { useShared, Link } from '@interactivevision/visitor-react';
import { SectionWrapper } from './wrappers';
import { route } from '@interactivevision/routing';
import { ReactComponent as Facebook } from '@assets/icons/facebook.svg';
import { ReactComponent as Instagram } from '@assets/icons/instagram.svg';
import { ReactComponent as Pintrest } from '@assets/icons/pintrest.svg';
import { ReactComponent as Eyes } from '@assets/icons/eyes.svg';
import ImgJgp from '../../../../public/static/images/footer-banner.jpg';
import ImgWebp from '../../../../public/static/images/footer-banner.webp';

export default function Footer() {
  const shared = useShared();

  return (
    <footer className={styles.footer}>
      <div className={styles.upper}>
        <SectionWrapper className={styles.content}>
          <header className={styles.header}>
            <h2 className={styles.title}>Zapisz się do naszego newslettera</h2>
            <h3 className={styles.subtitle}>
              <span>i odbierz 12% rabatu</span>
              <br/>
              na pierwsze zakupy
            </h3>
          </header>
          <nav className={styles.socials}>
            <span>Dołącz do nas</span>
            <a href="" target="_blank" rel="noreferrer" title="Facebook">
              <Facebook/>
            </a>
            <a href="" target="_blank" rel="noreferrer" title="Instagram">
              <Instagram/>
            </a>
            <a href="" target="_blank" rel="noreferrer" title="Pinterest">
              <Pintrest/>
            </a>
          </nav>
        </SectionWrapper>
        <div className={styles.image}>
          <picture>
            <source srcSet={ImgWebp} media="(min-width: 869px)" type="image/webp"/>
            <source srcSet={ImgJgp} media="(min-width: 869px)" type="image/jpg"/>
            <source srcSet="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdj+P///38ACfsD/QVDRcoAAAAASUVORK5CYII=" type="image/png" media="(max-width: 868px)"/>
            <img src={ImgJgp} alt="" loading="lazy"/>
          </picture>
        </div>
      </div>
      <SectionWrapper as="nav" className={styles.lower}>
        <Eyes className={styles.eyes}/>

        <Link href={route('homepage_module')} title={import.meta.env.VITE_APP_NAME} className={styles.logo}>
          <CompanyLogo/>
        </Link>
        <div className={styles.menu}>
          {shared?.menu.menu_bottom.map((group, index) => (
            <div className={styles.group} key={index}>
              {group.url ? (
                <Link href={group.url}>{group.name}</Link>
              ) : <div className={styles.title}>{group.name}</div>}
              <ul className={styles.links} key={index}>
                {group.children.map((child, index) => (
                  <li className={styles.link} key={index}>
                    <Link href={child.url} rel={child.rel} target={child.target}>{child.name}</Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </SectionWrapper>
    </footer>
  );
}